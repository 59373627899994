import { createSlice } from '@reduxjs/toolkit';

import { BasePropsState } from './base-props-slice.models';
import {
  setBaseProps,
  fetchCurrentUser,
  fetchNewCommentsStatus,
} from './base-props-slice.actions';

export const BASE_PROPS_SLICE_NAME = 'baseProps';

const initialState: BasePropsState = {
  language: 'en',
  newComments: {
    isLoading: false,
  },
  currentUser: {
    isLoading: false,
  },
};

const basePropsSlice = createSlice({
  name: BASE_PROPS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // setBaseProps
    builder.addCase(setBaseProps, (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    });

    // fetchCurrentUser
    builder.addCase(fetchCurrentUser.pending, (state) => {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          isLoading: true,
        },
      };
    });
    builder.addCase(fetchCurrentUser.fulfilled, (state, { payload }) => {
      return {
        ...state,
        currentUser: {
          data: payload,
          isLoading: false,
        },
      };
    });

    // fetchNewCommentsStatus
    builder.addCase(fetchNewCommentsStatus.pending, (state) => {
      return {
        ...state,
        newComments: {
          isLoading: true,
        },
      };
    });
    builder.addCase(fetchNewCommentsStatus.fulfilled, (state, { payload }) => {
      return {
        ...state,
        newComments: {
          isLoading: false,
          isEnabled: payload,
        },
      };
    });
    builder.addCase(fetchNewCommentsStatus.rejected, (state) => {
      return {
        ...state,
        newComments: {
          isLoading: false,
        },
      };
    });
  },
});

export const reducer = basePropsSlice.reducer;

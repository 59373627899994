// copy/paste from https://github.com/wix-private/app-market/blob/master/communities/communities-forum-app/src/app/services/build-deep-comment-url.js

const getPage = (index: number, countPerPage: number) =>
  Math.max(Math.ceil((index + 1) / countPerPage), 1);

export const buildDeepCommentUrl = ({
  categorySlug,
  postSlug,
  commentIndex,
  parentIndex,
  parentId,
  commentId,
}: {
  categorySlug: string;
  postSlug: string;
  commentIndex: number;
  parentIndex: number;
  parentId: string;
  commentId: string;
}) =>
  `/${categorySlug}/${postSlug}/p-${getPage(
    parentId ? parentIndex : commentIndex,
    20,
  )}/dl-${commentId}${parentId ? `-${parentId}` : ''}${
    parentId ? `-${getPage(commentIndex, 3)}` : ''
  }`;

import React, { FC } from 'react';
import { VisitorLogger } from '@wix/yoshi-flow-editor';

type BiEvent = { evid: number; [key: string]: any };

export interface BiLoggerClient {
  logEvent: (biEvent: BiEvent) => void;
}

export const BiLoggerContext = React.createContext<BiLoggerClient>(
  (null as any) as BiLoggerClient,
);

export const createBiLogger = (
  logger: VisitorLogger,
  options: {
    defaultConfig: { src: number; endpoint: string };
    defaultParams?: { [key: string]: any };
  },
) => {
  return ({
    evid: eventEvid,
    ...eventParams
  }: {
    evid: number;
    [key: string]: any;
  }) => {
    const reportProps = {
      src: options.defaultConfig.src,
      endpoint: options.defaultConfig.endpoint,
      evid: eventEvid,
      params: { ...options.defaultParams, ...eventParams },
    };

    logger.report(reportProps);
  };
};

interface BiLoggerContextProviderProps {
  logBiEvent: (biEvent: BiEvent) => void;
}

export const BiLoggerContextProvider: FC<BiLoggerContextProviderProps> = ({
  logBiEvent,
  children,
}) => {
  return (
    <BiLoggerContext.Provider
      value={{
        logEvent: logBiEvent,
      }}
    >
      {children}
    </BiLoggerContext.Provider>
  );
};

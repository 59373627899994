
  import userController from '/home/builduser/work/9b222164c6be38e0/packages/communities-forum-member-pages/src/components/MemberPosts/controller.ts';
  import createControllerWrapper from '@wix/yoshi-flow-editor/runtime/esm/pureControllerWrapper.js';

  
import wrapController from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/ooi';


  
var createHttpClient = null;

  
var initI18n = null;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
    DSN: 'https://0e6a29e4756740a8a63493e912ba2174@sentry.wixpress.com/2420',
    id: '0e6a29e4756740a8a63493e912ba2174',
    projectName: 'communities-forum-member-pages',
    teamName: 'forum',
    errorMonitor: true,
  };

  var experimentsConfig = {"centralized":true,"scopes":["wix-forum","ricos"]};

  var translationsConfig = {"enabled":false,"icuEnabled":false};

  var biConfig = null;

  var defaultTranslations = null;

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/9b222164c6be38e0/packages/communities-forum-member-pages/target/generated/bi/createBILogger.ts';

  const controllerOptions = {
    sentryConfig,
    biConfig,
    fedopsConfig,
    experimentsConfig,
    biLogger,
    translationsConfig,
    persistentAcrossPages: false,
    appName: "communities-forum-member-pages",
    componentName: "MemberPosts",
    appDefinitionId: "14724f35-6794-cd1a-0244-25fd138f9242",
    componentId: "14f0266c-e0a3-afc6-d07c-5be8e20949cd",
    projectName: "communities-forum-member-pages",
    defaultTranslations,
    shouldUseEssentials: true,
    withErrorBoundary: true,
    localeDistPath: "assets/locales"
  };

  const _controller = createControllerWrapper(userController, controllerOptions, {
    initI18n,
    createHttpClient,
    createExperiments,
  });

  export const wrap = wrapController;
  export const descriptor = {
    ...controllerOptions,
    id: controllerOptions.componentId,
    widgetType: "WIDGET_OUT_OF_IFRAME",
    controllerFileName: "/home/builduser/work/9b222164c6be38e0/packages/communities-forum-member-pages/src/components/MemberPosts/controller.ts",
  };

  export const controller = _controller
  export default controller;

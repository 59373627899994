import { IHttpClient } from '@wix/yoshi-flow-editor';

export type TranslationsService = ReturnType<typeof createTranslationsService>;

interface TranslationsData {
  [key: string]: string;
}

export const createTranslationsService = (
  {
    httpClient,
  }: {
    httpClient: IHttpClient;
  },
  config: { baseUrl: string; translationsName: string },
) => {
  const getTranslations = async (
    language: string,
  ): Promise<TranslationsData> => {
    const baseUrl = config.baseUrl;
    const translationsName = config.translationsName;
    const translationsUrl =
      baseUrl + translationsName + '_' + language + '.json';

    return httpClient
      .get<TranslationsData>(translationsUrl)
      .then(({ data }) => data);
  };

  return {
    _name_: 'translationsService' as const,
    getTranslations,
  };
};

import { useEditorSdk, useSettingsEnvironment } from '@wix/yoshi-flow-editor';
import { useFetch } from './use-fetch';

export interface useRequestProps {
  language: string;
  translationsName: string;
}

type FetchedData = { [key: string]: string };

export const useFetchTranslations = ({
  language,
  translationsName,
}: useRequestProps) => {
  const { editorSDK } = useEditorSdk();
  const { appDefinitionId } = useSettingsEnvironment();
  const state = useFetch<FetchedData>({
    getUrl: getUrl(editorSDK, appDefinitionId, translationsName, language),
  });

  return state;
};

const getUrl = (
  editorSDK: any,
  appDefinitionId: string,
  translationsName: string,
  language: string,
) => {
  return async (): Promise<string> => {
    const baseUrl = (
      await editorSDK.document.tpa.app.getDataByAppDefId(
        'token',
        appDefinitionId,
      )
    ).appFields.platform.baseUrls.translationsBaseUrl;

    return `${baseUrl + translationsName}_${language}.json`;
  };
};

import { Order } from '@wix/ambassador-forum-v1-post/types';

export enum UserRole {
  Moderator = 'moderator',
  Owner = 'owner',
  User = 'user',
}

export interface CurrentUser {
  badges: string[];
  contactId: string;
  createdDate: string;
  groups: string[];
  image: string;
  isBlocked: boolean;
  isOwner: boolean;
  name: string;
  permissions: { role: UserRole };
  privacyStatus: string;
  rolesList: string[];
  siteMemberId: string;
  slug: string;
  userId: string[];
  userPermissions: string[];
}

export interface PostViewsData {
  _id: string;
  categoryviewCountId: number;
}

// @TODO maybe rename to FetchFilterParams?
export interface FilterParams {
  sort?: string;
  order?: Order;
  offset?: number;
  size?: number;
  limit?: number;
}

export interface PostFetchParams extends FilterParams {
  userId: string;
}

import { IHttpClient } from '@wix/yoshi-flow-editor';
import { Order } from '@wix/ambassador-forum-v1-post/types';

import { memberPostsApi, memberCommentsApi } from '@api/member.api';
import { CommonRequest } from '../services.types';
import { CurrentUser, PostFetchParams } from './member.models';
import { buildDeepCommentUrl } from './member.utils';

export type MemberService = ReturnType<typeof createMemberService>;

// @TODO move to consts file
const PAGE_SIZE = 20;

export const createMemberService = ({
  httpClient,
  baseApi,
  communitiesApiBase,
}: {
  httpClient: IHttpClient;
  baseApi: CommonRequest;
  communitiesApiBase: CommonRequest;
}) => {
  const getCurrentUserData = async (): Promise<CurrentUser> => {
    return communitiesApiBase('/users/site-member/current');
  };

  const getPosts = async ({
    userId,
    sort = 'createdDate',
    order = Order.DESC,
    offset = 0,
    size = PAGE_SIZE,
    limit = PAGE_SIZE,
  }: PostFetchParams) => {
    try {
      const { data } = await httpClient.request(
        memberPostsApi(userId, {
          limit,
          offset,
          sort,
          order,
        }),
      );

      const paginationConfig = {
        itemCount: data.metaData?.total!,
        pageSize: PAGE_SIZE,
        pageCount: Math.ceil(data.metaData?.total! / PAGE_SIZE),
        currentFilter: {
          limit,
          offset,
          sort,
          order,
          size,
        },
      };

      return { pagination: paginationConfig, data: data.posts };
    } catch (e) {
      console.error(e);
      throw new Error('Failed to fetch user posts.');
    }
  };

  const deletePost = (postId: string) => {
    return communitiesApiBase.delete(`/posts/${postId}`);
  };

  const getComments = async ({
    userId,
    sort = 'createdDate',
    order = Order.DESC,
    offset = 0,
    size = PAGE_SIZE,
    limit = PAGE_SIZE,
  }: PostFetchParams) => {
    try {
      const {
        data: { comments, metaData },
      } = await httpClient.request(
        memberCommentsApi(userId, {
          limit,
          offset,
          sort,
          order,
        }),
      );

      const paginationConfig = {
        itemCount: metaData?.total!,
        pageSize: PAGE_SIZE,
        pageCount: Math.ceil(metaData?.total! / PAGE_SIZE),
        currentFilter: {
          limit,
          offset,
          sort,
          order,
          size,
        },
      };

      return { pagination: paginationConfig, data: comments };
    } catch (e) {
      throw new Error('Failed to fetch user comments.');
    }
  };

  const getNewCommentsStatus = () => {
    return httpClient
      .get<boolean>('/_serverless/comments-forum-integration/enabled')
      .then(({ data }) => data);
  };

  const deleteComment = ({
    postId,
    commentId,
  }: {
    postId: string;
    commentId: string;
  }) => {
    // @TODO check experiment for new comments
    // add new comments server delete action
    return communitiesApiBase.delete(`/posts/${postId}/comments/${commentId}`);
  };

  // Temporary workaround for comment deeplinks until backend provides all required data
  const getCommentDeeplink = async (comment: {
    id: string;
    post: { id: string; slug: string };
    category: { slug: string };
  }) => {
    const commentIndexData = await baseApi<{
      categorySlug: string;
      commentIndex: number;
      parentId: string;
      parentIndex: number;
      postSlug: string;
    }>(`/posts/comments/${comment.id}/index`);

    if (!commentIndexData) {
      return;
    }

    const commentDeeplink = buildDeepCommentUrl({
      commentId: comment.id,
      commentIndex: commentIndexData.commentIndex,
      parentId: commentIndexData.parentId,
      parentIndex: commentIndexData.parentIndex,
      postSlug: commentIndexData.postSlug,
      categorySlug: commentIndexData.categorySlug,
    });

    return commentDeeplink;
  };

  return {
    _name_: 'memberService' as const,
    getCurrentUserData,
    getPosts,
    deletePost,
    getComments,
    getCommentDeeplink,
    deleteComment,
    getNewCommentsStatus,
  };
};
